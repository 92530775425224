import React from 'react'
import { Title } from '../../components/UI/Title'
import { useState } from 'react';
import { Accordion, AccordionHeader, AccordionBody } from '@material-tailwind/react';
import { Link } from 'gatsby'

function Icon({ id , open }:{id: Number, open: Number}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="rgb(13, 85, 151)"
      strokeWidth={2}
      className={`${
        id === open ? "transform rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export const FAQsContent = () => {
  const [open1, setOpen1] = useState(0);
  const [open2, setOpen2] = useState(0);
  const [open3, setOpen3] = useState(0);
  const [open4, setOpen4] = useState(0);
  const [open5, setOpen5] = useState(0);
  const [open6, setOpen6] = useState(0);
  const [open7, setOpen7] = useState(0);
  const [open8, setOpen8] = useState(0);
  const [open9, setOpen9] = useState(0);
  const [open10, setOpen10] = useState(0);
  const [open11, setOpen11] = useState(0);
  //const [open12, setOpen12] = useState(0);
  //const [open13, setOpen13] = useState(0);
  const [open14, setOpen14] = useState(0);
  const [open15, setOpen15] = useState(0);
  const [open16, setOpen16] = useState(0);
  const [open17, setOpen17] = useState(0);
 
  const handleOpen1 = (value: React.SetStateAction<number>) => { setOpen1(open1 === value ? 0 : value); };
  const handleOpen2 = (value: React.SetStateAction<number>) => { setOpen2(open2 === value ? 0 : value); };
  const handleOpen3 = (value: React.SetStateAction<number>) => { setOpen3(open3 === value ? 0 : value); };
  const handleOpen4 = (value: React.SetStateAction<number>) => { setOpen4(open4 === value ? 0 : value); };
  const handleOpen5 = (value: React.SetStateAction<number>) => { setOpen5(open5 === value ? 0 : value); };
  const handleOpen6 = (value: React.SetStateAction<number>) => { setOpen6(open6 === value ? 0 : value); };
  const handleOpen7 = (value: React.SetStateAction<number>) => { setOpen7(open7 === value ? 0 : value); };
  const handleOpen8 = (value: React.SetStateAction<number>) => { setOpen8(open8 === value ? 0 : value); };
  const handleOpen9 = (value: React.SetStateAction<number>) => { setOpen9(open9 === value ? 0 : value); };
  const handleOpen10 = (value: React.SetStateAction<number>) => { setOpen10(open10 === value ? 0 : value); };
  const handleOpen11 = (value: React.SetStateAction<number>) => { setOpen11(open11 === value ? 0 : value); };
  //const handleOpen12 = (value) => { setOpen12(open12 === value ? 0 : value); };
  //const handleOpen13 = (value) => { setOpen13(open13 === value ? 0 : value); };
  const handleOpen14 = (value: React.SetStateAction<number>) => { setOpen14(open14 === value ? 0 : value); };
  const handleOpen15 = (value: React.SetStateAction<number>) => { setOpen15(open15 === value ? 0 : value); };
  const handleOpen16 = (value: React.SetStateAction<number>) => { setOpen16(open16 === value ? 0 : value); };
  const handleOpen17 = (value: React.SetStateAction<number>) => { setOpen17(open17 === value ? 0 : value); };

  return (
    <div>
      <Title tw="text-center">Frequently Asked Questions</Title>

      <div tw="mt-10 space-y-6">
      
        <h5 tw="text-commerciumDarkBlue text-2xl font-bold">Background Information on Commercium and SPDI's</h5>


        <Accordion open={open1 === 1} icon={<Icon id={1} open={open1} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen1(1)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Is Commercium a bank?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Yes - Commercium is a state-chartered bank under Wyoming's special purpose depository 
              institution ("SPDI") law. As a depository institution, Commercium meets the definition of a bank 
              under state and federal law.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open2 === 2} icon={<Icon id={2} open={open2} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen2(2)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>How is a Special Purpose Depository Institution (SPDI) Compared/different to a Trust Company (TC)?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>A Wyoming-chartered SPDI is a full reserve bank that receives deposits and conducts other 
              activity related to the business of banking, including custody, asset servicing, and fiduciary asset 
              management. SPDIs can handle digital assets, such as virtual currencies, digital securities, and 
              digital consumer assets. SPDIs may deal in traditional assets as well, by serving as a vehicle for 
              business cash management, operational accounts, and any other purpose permitted under applicable law.</p><br />

            <p>A TC is a separate corporate entity owned by a bank or other financial institution, law firm, or 
              independent partnership. Its function is to manage trusts, trust funds, and estates for individuals, 
              businesses, and other entities. A trust is an arrangement that allows a third party or trustee to 
              hold assets or property for a beneficiary or beneficiaries. TCs get their title from the fact that 
              they act in a fiduciary capacity for their clients—as trustees.</p><br />

            <p><u>Differences</u></p><br />

            <p>SPDIs and TCs are different in multiple ways. Important differences include:</p>
              <ol tw="list-decimal list-outside ml-14">
                <li>SPDIs are regulated as banks and must comply with banking regulations and special, strict 
                  regulations applicable to SPDIs (for example, have their customer deposits of fiat currency at 
                  all times backed 100% or more by unencumbered liquid assets, which include U.S. currency and 
                  level 1 high-quality liquid assets).</li>
                <li>SPDIs advance qualified custody of securities and digital assets. SPDIs may provide custodial 
                  services for digital assets and perform authorized transactions for customers. Further, the 
                  Wyoming SPDI laws mean that only SPDIs can provide an effective bailment while permitting direct 
                  ownership of securities, all provable through the blockchain.</li>
                <li>SPDIs may conduct activity under Wyoming regulations tailored to digital assets, which address 
                  issues such as technology controls, transaction handling, and custody operations for digital 
                  assets.</li>
                <li>SPDIs operate under Wyoming law that defines digital assets in conjunction with the Wyoming 
                  Uniform Commercial Code and describes, among things, perfection and priority of security 
                  interests in digital assets.</li>
                <li>SPDIs may resemble custody banks because they will likely be predominantly engaged in custody, 
                  safekeeping, and asset servicing activities. A custody bank is focused on safekeeping assets, 
                  fiduciary management, transaction processing and settlement, and providing an “on/off” ramp to 
                  securities markets, commodities markets, and customer bank accounts.</li>
                <li>A SPDI can engage in a wider range of activities than a TC.</li>
                <li>A TC is often owned by another entity, such as a bank.</li>
              </ol>
          </AccordionBody>
        </Accordion>

        <Accordion open={open3 === 3} icon={<Icon id={3} open={open3} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen3(3)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Are deposits at Commercium FDIC-insured?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>No, deposits with Commercium are not FDIC-insured. However, Commercium must comply with all SPDI 
            reserve requirements, which ensures the safety of customer deposits. Please see the question - 
            "how are customer deposits protected without FDIC insurance?"</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open4 === 4} icon={<Icon id={4} open={open4} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen4(4)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>How Are Customer Deposits Protected Without FDIC Insurance?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Commercium, unlike many banks, does not engage in lending or fractional reserve banking, 
              instead, 100% of customer deposits are held in reserve with additional protective measures. 
              Here is how deposits are safeguarded in the event of bankruptcy:</p>
            <ul tw="list-disc list-outside ml-14">
              <li><b>100% Reserve in Liquid Assets:</b> All customer deposits are held in unencumbered, high-quality 
                liquid assets. Specifically, Commercium's investment policy mandates retaining at least 20% in 
                cash. The remainder is invested in US Treasury bills with a maximum tenure of 3 months. This, 
                combined with constant liquidity management ensures that all customers will be able to access their 
                deposits immediately. In the unlikely event of a very severe run on the bank occurs, 100% of all 
                deposited funds will be available within 3 months. However due to the nature of 100% reserves, the 
                chances of a bank run are much less likely than at a fractional reserve bank.</li>
              <li><b>Capital Reserves:</b> Beyond holding 100% of customer deposits, Commercium will 
                maintain a capital reserve of between 2% and 4% (final amount pending) against all assets. 
                For instance, for every $1 billion in customer deposits, a capital reserve of $20 
                million to $40 million will be required.</li>
              <li><b>Legal Framework & Guidelines:</b> Although the Wyoming Banking Division hasn't yet overseen a 
                liquidation, their procedures are outlined in section 12-12-123 of the SPDI Charter under the 
                voluntary dissolution section.</li>
                <ul tw="list-disc list-outside ml-14">
                  <li>
                    <Link to="https://wyoleg.gov/statutes/compress/title13.pdf" target="_blank" tw="underline hover:text-blue-800">
                      Title 13 - Banks, Banking and Finance
                    </Link>
                  </li>
                </ul>
              <li><b>Capital Requirements:</b> Prior to opening, Commercium is required to retain capital of at 
                least  $30 million. As the bank grows its assets, the minimum capital requirement will be either 
                $30 million or 2-4% of assets, whichever is greater.</li>
                
              <li><b>Asset Pledge:</b> In addition, $15 million of bank assets will be legally pledged to the 
                State Banking Division, ensuring they can promptly address potential harm to the SPDI charter 
                during any forced or voluntary liquidation. This pledged amount is accessible immediately, even 
                before any remaining capital post-liquidation.</li>
            </ul>
          </AccordionBody>
        </Accordion>

        <Accordion open={open5 === 5} icon={<Icon id={5} open={open5} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen5(5)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Why Commercium DOES NOT present additional risks than a domestic bank.</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <ol tw="list-decimal list-outside ml-14">
              <li>Our Banking services are traditional banking products:</li>
                <ul tw="list-disc list-outside ml-14">
                  <li>USD checking, savings, escrow accounts, FBO accounts</li>
                  <li>Payment services (ACH, wire, Bill Pay)</li>
                </ul>
              <li>Full Reserve Bank versus Fractional Reserve Banking:</li>
                <ul tw="list-disc list-outside ml-14">
                  <li>100% of Commercium customer deposits are held in reserves.</li>
                  <li>4% or $30 million capital, whichever is greater, on top of 100% reserves.</li>
                  <li>We cannot leverage our capital.</li>
                  <li>Our Charter prohibits us from engaging in any form of lending.</li>
                  <li>Our Charter prohibits the issuance of credit cards.</li>
                </ul>
              <li>We have no immediate plans to enter the crypto space, and will only do so once clear US 
                regulation has been established.</li>
              <li>We will only enter the Stablecoin space once full regulatory approvals are received for the 
                industry.</li>
              <li>Our tokenization of financial and real-world assets have received US regulatory approval 
                in the marketplace.</li>
            </ol>
          </AccordionBody>
        </Accordion>

        <Accordion open={open6 === 6} icon={<Icon id={6} open={open6} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen6(6)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>What is Fractional Reserve Banking?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Fractional reserve banking is a banking system where only a fraction of their depositors' 
              balances are available for withdrawals, with the rest used to lend out to other customers. For 
              example: if a customer deposits $100 in their bank with a reserve requirement of 10%, $10 is 
              cash and $90 is lent out to other customers. If depositors simultaneously lose confidence, 
              especially if the bank's investments underperform or lose money, and try to withdraw their 
              funds, the bank runs the possibility of not having enough liquid cash on hand to cover all 
              withdrawals, leading to a bank run.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open7 === 7} icon={<Icon id={7} open={open7} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen7(7)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Do all States require insurance or to be federally regulated?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Historically, the choice whether states would require their banks to be insured or federally 
              regulated rested solely with the states, not with Washington, D.C, and until approximately 1990 
              most state laws were silent on the topic. Banks simply chose to be FDIC insured and federally 
              regulated, and the Fed and FDIC routinely granted them access. But five U.S. states held back, 
              keeping flexibility for themselves and a check on potential overreach by federal bank regulators. 
              The five states - Connecticut, Maine, Nebraska, Vermont and Wyoming - enacted bank charters that 
              neither require insurance nor federal regulation. Such uninsured state banks are prohibited from 
              lending (either explicitly by law or functionally), and therefore hold 100% cash to back customer 
              deposits plus up to 8% of deposits as an additional capital requirement.</p>
          </AccordionBody>
        </Accordion>

      <h5 tw="text-commerciumDarkBlue text-2xl font-bold">Commercium's Business Plan</h5>

        <Accordion open={open8 === 8} icon={<Icon id={8} open={open8} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen8(8)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Can you take both commercial and retail accounts and deposits?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>As part of CFI's core banking implementation, CFI will test and have approval to launch products 
              and services for commercial and retail customers. CFI's plan for day 1 is commercial, but by 
              testing retail products this means CFI can launch at a much quicker pace later on.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open9 === 9} icon={<Icon id={9} open={open9} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen9(9)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Can you take accounts from non-US citizens or offer products to non-US citizens?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>The Wyoming Division of Banking has expressed no objections to allowing SPDI's to accept non-US 
              citizen customers or deposits. However, for the initial opening of the Bank we will only be 
              focusing on accounts for US residents.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open10 === 10} icon={<Icon id={10} open={open10} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen10(10)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>What is the "customer service" plan for Commercium?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>As part of our core banking implementation (checking accounts, savings accounts, bank services) 
              with NYMBUS, we have secured alongside a back-office capability and a call center capability. 
              These two areas from NYMBUS who already serve many banks, will be linked with the proposed 
              Commercium back-office capabilities.</p>
          </AccordionBody>
        </Accordion>

      <h5 tw="text-commerciumDarkBlue text-2xl font-bold">Regulatory and Compliance</h5>

        <Accordion open={open11 === 11} icon={<Icon id={11} open={open11} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen11(11)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>How is Commercium regulated?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Commercium is a state-chartered U.S. bank, regulated by the Wyoming Division of Banking, and 
              is subject to the same standards that govern all banks, including bank-level capital requirements 
              and bank-level compliance requirements (including the higher standard that applies to banks 
              called the "Customer Due Diligence Rule"). Additionally, as a regulated bank, Commercium will 
              be subject to frequent supervisory examinations that may not apply to non-banks.</p>
          </AccordionBody>
        </Accordion>

      <h5 tw="text-commerciumDarkBlue text-2xl font-bold">Blockchain and Tokenization</h5>

        <Accordion open={open14 === 14} icon={<Icon id={14} open={open14} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen14(14)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Since Commercium uses blockchain technology, how is network security managed and tested?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>We will leverage vendor-provided blockchain technology. As with any software vendor, the security 
              measures will be proven through their documentation and will first demonstrate software compliance 
              through initial testing before being accepted and integrated. User acceptance testing, 
              penetration testing, and deployment testing procedures, etc. will be developed for system approval 
              through both internal and third-party evaluations before we are certified by the Wyoming Division 
              of Banking.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open15 === 15} icon={<Icon id={15} open={open15} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen15(15)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>How will Commercium ensure safety and security of the customer digital assets?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Commercium will ensure the safety and security of customer digital assets through a multi-faceted 
              approach. We will partner with a reputable wallet custody provider known for its robust rules-based 
              security measures, designed to prevent unauthorized actions, even internally. Additionally, 
              Commercium will partner with Chainalysis, a renowned blockchain analytics and Know-Your-Transaction 
              vendor to ensure that all transactions comply with stringent US regulations and anti-money 
              laundering (AML) laws. Furthermore, to enhance security, private keys will be broken up in such a 
              way that no single location or individual can gain full access to them.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open16 === 16} icon={<Icon id={16} open={open16} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen16(16)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>What types of blockchains does Commercium intend to use?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>With the recent decisions from the Federal Reserve as it pertains to banking, it has become apparent 
              that public blockchains, such as Ethereum, may not be favored or allowed in banking.  With this recent 
              development, we are considering a permissioned or private blockchain for our use, just as was 
              presented in our business case to the Wyoming Division of Banking to receive our charter.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open17 === 17} icon={<Icon id={17} open={open17} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen17(17)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>How is the bank set up to support a USA digital currency?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Part of our software vendor selection will include the ability to support the integration of multiple 
              blockchain types.  This is designed to ensure we can adapt to any regulatory-approved developments.  While 
              no one can yet guarantee support of any future US-backed digital currency, we are following the development 
              of the Central Bank Digital Currency (CBDC) to ensure we have the maximum opportunity to complement these 
              developments.</p>
          </AccordionBody>
        </Accordion>

      </div>

    </div>
  )
}